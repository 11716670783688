/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import * as holiday_jp from '@holiday-jp/holiday_jp';
import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import {
  CourseSlotSettingWithCourse,
  DisplayMode,
} from '../../../@interfaces/course-slot-setting';
import {
  isReservationTablePreviewData,
  PREVIEW_MESSAGE_TYPE_RESERVATION_TABLE,
} from '../../../@interfaces/preview/reservation-table-preview';
import { ReservationTableService } from '../../../core/services/reservation-table-service';
import {
  createDateRange,
  DateFormat,
  DateRange,
  HolidayChecker,
  IDate,
  ReservationTable,
  SlotSetting,
  toDateStringByDate,
} from '../../../core/types/reservation-types';
import { DEFAULT_PRIMARY_COLOR } from '../../../models/theme';
import {
  CourseSlotSettingForReservationTable,
  ReservationTableContent,
} from '../../shop-course/components/ReservationTableContent';

const styles = {
  container: css`
    padding: 20px;
    max-width: 692px;
    margin: 0 auto;
    margin-bottom: 100px;
  `,
  options: css`
    padding: 20px;
    margin-bottom: 20px;
    font-size: 14px;
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
  `,
  optionsInfo: css`
    font-size: 12px;
    color: #666;
    margin-bottom: 10px;
  `,
  course: css`
    margin-bottom: 10px;
    padding: 10px;
    select {
      padding: 5px;
      margin-right: 10px;
    }
    button {
      padding: 5px 10px;
    }
    background-color: #eee;
    border: 1px solid #ddd;
    border-radius: 5px;
  `,
};

const DEFAULT_DATE_RANGE = createDateRange(
  dayjs().format('YYYY-MM-DD') as DateFormat,
  dayjs().add(6, 'day').format('YYYY-MM-DD') as DateFormat
);

const holidayJpChecker: HolidayChecker = (date: IDate) => {
  return holiday_jp.isHoliday(new Date(toDateStringByDate(date)));
};

export default function ReservationTablePreviewPage() {
  const [reservationTable, setReservationTable] = useState<ReservationTable>({
    dateRange: DEFAULT_DATE_RANGE,
    dates: [],
  });
  const [dateRange, setDateRange] = useState<DateRange>(DEFAULT_DATE_RANGE);
  const [slotSetting, setSlotSetting] = useState<SlotSetting>();
  const [displayMode, setDisplayMode] = useState<DisplayMode>('default');
  const [showsSlotCapacity, setShowsSlotCapacity] = useState(false);
  const [hidesNoSlotDate, setHidesNoSlotDate] = useState(false);
  const [minutesRequired, setMinutesRequired] = useState<number | undefined>();
  const [courseSlotSettings, setCourseSlotSettings] = useState<
    CourseSlotSettingWithCourse[]
  >([]);
  const [selectedCourseSlotSettingId, setSelectedCourseSlotSettingId] =
    useState<number | undefined>();

  useEffect(() => {
    window.addEventListener('message', function (e) {
      if (
        process.env.REACT_APP_PREVIEW_MSG_SENDER_ORIGIN === e.origin &&
        e.data?.type === PREVIEW_MESSAGE_TYPE_RESERVATION_TABLE
      ) {
        // プレビューで項目の変更を表示するための処理
        const { data } = e.data;
        if (isReservationTablePreviewData(data)) {
          const { rules, courseSlotSettings } = data;
          const slotSetting: SlotSetting = {
            rules: rules,
            isHoliday: holidayJpChecker,
            courseId: -1,
          };
          setSlotSetting(slotSetting);
          setCourseSlotSettings(courseSlotSettings);
        }
      }
    });
  }, []);

  useEffect(() => {
    if (!slotSetting) {
      return;
    }
    const newTable = new ReservationTableService().buildReservationTable(
      {
        ...slotSetting,
        minutesRequired,
      },
      dateRange,
      []
    );
    const tableWithoutUnadvisableSlots: ReservationTable = {
      ...newTable,
      dates: newTable.dates.map((date) => {
        return {
          ...date,
          slots: date.slots.map((slot) => {
            return {
              ...slot,
              capacity: {
                ...slot.capacity,
                // 予約不可の場合は0を表示
                // frontend-api/src/services/reservation-service.tsの
                // getPublicReservationTable内の処理に合わせる
                total: slot.reserved.canReserve ? slot.capacity.total : 0,
              },
            };
          }),
        };
      }),
    };
    setReservationTable(tableWithoutUnadvisableSlots);
  }, [dateRange, slotSetting, minutesRequired]);

  const courseSlotSetting: CourseSlotSettingForReservationTable =
    useMemo(() => {
      const courseSlotSetting: CourseSlotSettingForReservationTable = {
        showsSlotCapacity: showsSlotCapacity ? 1 : 0,
        hidesNoSlotDate: hidesNoSlotDate,
        shopCourseSetting: {
          minutesRequired: minutesRequired,
        },
      };
      return courseSlotSetting;
    }, [hidesNoSlotDate, showsSlotCapacity, minutesRequired]);

  const handleApplySelectedCourseSlotSetting = () => {
    if (selectedCourseSlotSettingId === undefined) {
      return;
    }
    const courseSlotSetting = courseSlotSettings.find(
      (c) => c.id === selectedCourseSlotSettingId
    );
    if (!courseSlotSetting) {
      return;
    }
    setDisplayMode(courseSlotSetting.displayMode);
    setShowsSlotCapacity(courseSlotSetting.showsSlotCapacity === 1);
    setHidesNoSlotDate(courseSlotSetting.hidesNoSlotDate ?? false);
    setMinutesRequired(
      courseSlotSetting.shopCourseSetting?.minutesRequired ?? undefined
    );
    setSelectedCourseSlotSettingId(undefined);
  };

  return (
    <div>
      <div css={styles.options}>
        <div css={styles.optionsInfo}>
          ※
          下記設定はプレビュー時のみ反映されます。公開された画面の表示設定は別途コース詳細設定から行ってください。
        </div>
        <div css={styles.course}>
          <select
            id="course"
            value={selectedCourseSlotSettingId}
            onChange={(e) => {
              if (e.target.value === '') {
                setSelectedCourseSlotSettingId(undefined);
                return;
              }
              setSelectedCourseSlotSettingId(Number(e.target.value));
            }}
          >
            <option value="">コース詳細設定から設定を読み込み</option>
            {courseSlotSettings.map((courseSlotSetting) => {
              return (
                <option key={courseSlotSetting.id} value={courseSlotSetting.id}>
                  {courseSlotSetting.course.name}
                </option>
              );
            })}
          </select>
          <button
            disabled={selectedCourseSlotSettingId === undefined}
            onClick={handleApplySelectedCourseSlotSetting}
          >
            反映
          </button>
        </div>
        <div>
          <input
            id="displayMode"
            type="checkbox"
            checked={displayMode === 'table'}
            onChange={(e) => {
              setDisplayMode(e.target.checked ? 'table' : 'default');
            }}
          />
          <label htmlFor="displayMode">テーブル形式で表示</label>
        </div>
        <div>
          <input
            id="showsSlotCapacity"
            type="checkbox"
            checked={showsSlotCapacity}
            onChange={(e) => {
              setShowsSlotCapacity(e.target.checked);
            }}
          />
          <label htmlFor="showsSlotCapacity">予約可能数を表示</label>
        </div>
        <div>
          <input
            id="hidesNoSlotDate"
            type="checkbox"
            checked={hidesNoSlotDate}
            onChange={(e) => {
              setHidesNoSlotDate(e.target.checked);
            }}
          />
          <label htmlFor="hidesNoSlotDate">予約枠がない日付を隠す</label>
        </div>
        <div>
          <div>
            <label htmlFor="minutesRequired">コースの所要時間</label>
          </div>
          <input
            id="minutesRequired"
            type="number"
            value={minutesRequired || ''}
            onChange={(e) => {
              if (e.target.value === '') {
                setMinutesRequired(undefined);
                return;
              }
              setMinutesRequired(Number(e.target.value));
            }}
          />
          分
        </div>
      </div>
      <div css={styles.container}>
        <ReservationTableContent
          reservationTable={reservationTable}
          dateRange={dateRange}
          primaryColor={DEFAULT_PRIMARY_COLOR}
          courseSlotSetting={courseSlotSetting}
          widgetMode={false}
          displayMode={displayMode}
          directContactInfo={{
            allowLateReservation: 'none',
            allowReservationWhenFull: 'none',
            contactLineId: null,
            contactTelNumber: null,
          }}
          previewMode={true}
          onChangeDateRange={setDateRange}
        />
      </div>
    </div>
  );
}
