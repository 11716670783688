/** @jsxImportSource @emotion/react */

import React from 'react';
import { FieldValues, UseFormMethods } from 'react-hook-form';
import {
  AnyField,
  Field,
  FieldResponse,
  FieldResponseValue,
  FormResponse,
} from '../../../../../core/types/reservation-form-types';
import { CheckboxFormField } from './CheckboxFormField';
import { DateFormField } from './DateFormField';
import { EmailFormField } from './EmailFormField';
import { NameFormField } from './NameFormField';
import { NumberFormField } from './NumberFormField';
import { RadioFormField } from './RadioFormField';
import { TelFormField } from './TelFormField';
import { TextareaFormField } from './TextareaFormField';
import { TextFormField } from './TextFormField';

type FormFieldProps = {
  field: AnyField;
  formResponse: FormResponse;
  primaryColor: string;
  validationContext: UseFormMethods;
  onChangeValue?: (field: Field, newValues: FieldResponseValue[]) => void;
};

export const FormField = ({
  field,
  formResponse,
  primaryColor,
  validationContext,
  onChangeValue,
}: FormFieldProps) => {
  const fieldResponse: FieldResponse = formResponse.fields.find((value) => {
    return value.uid === field.uid;
  }) || {
    uid: field.uid,
    values: [],
  };
  if (field.type === 'text') {
    return (
      <TextFormField
        key={field.uid}
        field={field}
        fieldResponse={fieldResponse}
        primaryColor={primaryColor}
        onChangeValue={onChangeValue}
        validationContext={validationContext}
      />
    );
  } else if (field.type === 'textarea') {
    return (
      <TextareaFormField
        key={field.uid}
        field={field}
        fieldResponse={fieldResponse}
        primaryColor={primaryColor}
        onChangeValue={onChangeValue}
        validationContext={validationContext}
      />
    );
  } else if (field.type === 'radio') {
    return (
      <RadioFormField
        key={field.uid}
        field={field}
        fieldResponse={fieldResponse}
        primaryColor={primaryColor}
        onChangeValue={onChangeValue}
        validationContext={validationContext}
      />
    );
  } else if (field.type === 'checkbox') {
    return (
      <CheckboxFormField
        key={field.uid}
        field={field}
        fieldResponse={fieldResponse}
        primaryColor={primaryColor}
        onChangeValue={onChangeValue}
        validationContext={validationContext}
      />
    );
  } else if (field.type === 'tel') {
    return (
      <TelFormField
        key={field.uid}
        field={field}
        fieldResponse={fieldResponse}
        primaryColor={primaryColor}
        onChangeValue={onChangeValue}
        validationContext={validationContext}
      />
    );
  } else if (field.type === 'email') {
    return (
      <EmailFormField
        key={field.uid}
        field={field}
        fieldResponse={fieldResponse}
        primaryColor={primaryColor}
        onChangeValue={onChangeValue}
        validationContext={validationContext}
      />
    );
  } else if (field.type === 'name') {
    return (
      <NameFormField
        key={field.uid}
        field={field}
        fieldResponse={fieldResponse}
        primaryColor={primaryColor}
        onChangeValue={onChangeValue}
        validationContext={validationContext}
      />
    );
  } else if (field.type === 'number') {
    return (
      <NumberFormField
        key={field.uid}
        field={field}
        fieldResponse={fieldResponse}
        primaryColor={primaryColor}
        onChangeValue={onChangeValue}
        validationContext={validationContext}
      />
    );
  } else if (field.type === 'date') {
    return (
      <DateFormField
        key={field.uid}
        field={field}
        fieldResponse={fieldResponse}
        primaryColor={primaryColor}
        onChangeValue={onChangeValue}
        validationContext={validationContext}
      />
    );
  } else {
    return null;
  }
};
