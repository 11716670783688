/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import React from 'react';
import { DateField } from '../../../../../core/types/reservation-form-types';
import { FieldErrorMessage } from '../FieldErrorMessage';
import { BaseFormFieldProps } from './BaseFormField';
import { FieldDescription } from './FieldDescription';
import { FieldLabel } from './FieldLabel';
import { buildFieldValidation } from './input-helpers';
import { styles as inputStyles } from './input-styles';

const styles = {
  // iOSでは以下のスタイルがないと見た目がイマイチになる
  inputDateField: css({
    '-webkit-appearance': 'none',
    background: '#fff',
    height: '2.875em',
  }),
  clearOptionLabel: css({
    display: 'block',
    width: 'fit-content',
    lineHeight: '100%',
    cursor: 'pointer',
    fontSize: '12px',
    margin: 12,
    padding: 4,
  }),
};

type DateFormFieldProps = BaseFormFieldProps & {
  field: DateField;
};

export function DateFormField(props: DateFormFieldProps) {
  const {
    primaryColor,
    field,
    fieldResponse,
    onChangeValue,
    validationContext,
  } = props;
  const { errors, register, trigger } = validationContext;

  const fieldValidation = buildFieldValidation(field);

  const handleChangeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    onChangeValue?.(field, [newValue]);
  };

  const ClearOption = () => (
    <div>
      <input
        id={`${field.uid}_clear-option`}
        name={field.uid}
        type="button"
        value={undefined}
        onClick={() => onChangeValue?.(field, [])}
        style={{ display: 'none' }}
      />
      <label
        htmlFor={`${field.uid}_clear-option`}
        css={styles.clearOptionLabel}
        style={{ cursor: 'pointer', color: primaryColor }}
      >
        日付の選択をクリアする
      </label>
    </div>
  );

  const fieldError = errors[field.uid];

  return (
    <div>
      <div css={inputStyles.inputLabel}>
        <div css={inputStyles.inputTitle}>
          <FieldLabel field={field} />
        </div>
        <FieldErrorMessage field={field} errors={errors} />
      </div>
      <FieldDescription description={field.description} />
      <input
        type="date"
        name={field.uid}
        value={(fieldResponse.values[0] as string) || ''}
        onChange={handleChangeValue}
        ref={register(fieldValidation)}
        css={[
          inputStyles.inputField,
          styles.inputDateField,
          fieldError && inputStyles.inputErrorField,
        ]}
        onBlur={async () => await trigger(field.uid)}
      />
      {!field.condition?.public.required && fieldResponse.values[0] && (
        <ClearOption />
      )}
    </div>
  );
}
