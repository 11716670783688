const PARAM_INFLOW_SOURCE = '_src';

const isValidUrl = (url: string): boolean => {
  try {
    new URL(url);
    return true;
  } catch {
    return false;
  }
};

export function getInflowSource(paramsString: string): string | null {
  //流入元の設定がなければリファラーのドメインを返す
  const inflowSource = new URLSearchParams(paramsString).get(
    PARAM_INFLOW_SOURCE
  );
  if (inflowSource) {
    return inflowSource;
  }
  //リファラーが自サイト内のリンクであればnullを返す
  const referrer = document.referrer;
  const currentUrl = new URL(window.location.href);
  if (referrer && new URL(referrer).host === currentUrl.host) {
    return null;
  }
  //リファラーがURLであればドメインを返す
  if (isValidUrl(referrer)) {
    return new URL(referrer).hostname;
  }
  return null;
}

export function setInflowSourceParam(
  inflowSource: string | null,
  baseParamsString?: string
): string {
  if (!inflowSource && !baseParamsString) {
    return '';
  }
  const params = new URLSearchParams(baseParamsString);
  if (inflowSource) {
    params.set(PARAM_INFLOW_SOURCE, inflowSource);
  }
  return '?' + params.toString();
}
