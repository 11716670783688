/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { DirectContactInfo } from './direct-contact';

type DirectContactButtonProps = {
  contactMethod: 'line' | 'tel' | 'none';
  directContactInfo: DirectContactInfo;
};

export const DirectContactButton = ({
  contactMethod,
  directContactInfo,
}: DirectContactButtonProps): JSX.Element => {
  if (contactMethod === 'line') {
    return (
      <a
        css={styles.line}
        href={`https://line.me/R/ti/p/@${encodeURIComponent(
          directContactInfo.contactLineId ?? ''
        )}`}
      >
        LINE
      </a>
    );
  } else if (contactMethod === 'tel') {
    return (
      <a
        css={styles.tel}
        href={`tel:${directContactInfo.contactTelNumber ?? ''}`}
      >
        TEL
      </a>
    );
  } else {
    return <span></span>;
  }
};

const styles = {
  line: css`
    color: #06c755 !important;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 4px;
  `,
  tel: css`
    color: #0073e6 !important;
    font-size: 14px;
    font-weight: bold;
    padding: 4px 8px;
    border-radius: 4px;
  `,
};
