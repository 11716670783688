import dayjs from 'dayjs';
import {
  AllowLateReservation,
  AllowReservationWhenFull,
} from '../../../../@interfaces/shop-setting';
import {
  IDate,
  ReservedSlotCapacity,
  toDayjs,
} from '../../../../core/types/reservation-types';

export type DirectContactInfo = {
  allowLateReservation: AllowLateReservation;
  allowReservationWhenFull: AllowReservationWhenFull;
  contactLineId: string | null;
  contactTelNumber: string | null;
};

export const isSlotFull = (
  date: IDate,
  slot: ReservedSlotCapacity,
  allowReservationWhenFull: AllowReservationWhenFull
) => {
  const isBeforeNow = dayjs().isAfter(
    toDayjs(date)
      .hour(slot.slot.timeRange.end.hour)
      .minute(slot.slot.timeRange.end.minute)
  );

  return (
    (allowReservationWhenFull === 'tel' ||
      allowReservationWhenFull === 'line') &&
    !slot.reserved.canReserve &&
    slot.reserved.total > 0 &&
    !slot.capacity.startNotAllowed &&
    (slot.capacity.initialTotal ?? 0) > 0 &&
    !isBeforeNow
  );
};

export const isSlotLate = (
  date: IDate,
  slot: ReservedSlotCapacity,
  allowLateReservation: AllowLateReservation
) => {
  const isBeforeNow = dayjs().isAfter(
    toDayjs(date)
      .hour(slot.slot.timeRange.start.hour)
      .minute(slot.slot.timeRange.start.minute)
  );

  return (
    (allowLateReservation === 'tel' || allowLateReservation === 'line') &&
    slot.reserved.canReserve &&
    (slot.capacity.initialTotal ?? 0) > 0 &&
    !isBeforeNow
  );
};
