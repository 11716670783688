/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { calcSelectedOptionalFields } from '../../../@interfaces/course-slot-setting';
import useCourse from '../../../api/use-course';
import { useCustomDomainAttachedShop } from '../../../api/use-custom-domain-attached-shop';
import useReservation from '../../../api/use-reservation';
import useReservationTable from '../../../api/use-reservation-table';
import { DomainError, validDomain } from '../../../components/DomainError';
import { Loading, SelectedReserveInfo } from '../../../components/Shared';
import { SelectedOptionalField } from '../../../core/types/reservation-resource-types';
import useStorage from '../../../hooks/use-storage';
import { useTrackLocation } from '../../../hooks/use-track-location';
import useUserScript from '../../../hooks/use-user-script';
import {
  createDefaultFormResponse,
  getStorageKey,
} from '../../form/routes/FormPage';
import { Calendar } from '../components/calendar/Calendar';
import { EmptyReservationTable } from '../components/EmptyReservationTable';
import { HeaderContent } from '../components/HeaderContent';
import { InfoMessage } from '../components/InfoMessage';
import { MinuteRequiredInfo } from '../components/MinuteRequiredInfo';
import { ReservationTableContent } from '../components/ReservationTableContent';
import { ResourceForm } from '../components/ResourceForm';
import { useDateRange } from '../hooks/use-date-range';
import { useForceOpenCalendar } from '../hooks/use-force-open-calendar';
import { useNormalizeLocationUrl } from '../hooks/use-normalize-location-url';
import { useResourceResponse } from '../hooks/use-resource-response';
import {
  calcInitialEndDate,
  calcInitialStartDate,
  getPrimaryColor,
  getWeeklyReservationsLength,
} from './page-helpers';
import { styles } from './page-styles';

type PageParams = {
  workspaceUid: string;
  shopUid: string;
  courseUid: string;
};

/**
 * 予約日時変更画面
 * リクエストパラメータ reservationKey を元に予約情報を取得し
 * 予約日時変更画面を表示する
 */
export default function ChangeDateTimePage() {
  useTrackLocation();
  const { workspaceUid, shopUid, courseUid } = useParams<PageParams>();
  const { shopSetting, isLoadingCourse, displayMode } = useCourse(
    workspaceUid,
    shopUid,
    courseUid
  );

  const searchParams = new URL(window.location.href).searchParams;
  // ウィジェットモード: trueの場合埋め込みに最適化された表示になります。
  // 日時変更画面はウィジェットモードはfalse
  const widgetMode = false;
  // ノーマルモード: trueの場合通常の予約表示ルールが適用されます。
  const normalParam = Number(searchParams.get('normal'));
  const normalMode = normalParam === 1 ? true : false;

  const startDateParam = calcInitialStartDate(searchParams, shopSetting);
  const endDateParam = calcInitialEndDate(
    searchParams,
    widgetMode,
    normalMode,
    startDateParam
  );

  // 予約時間変更画面の予約キー
  const reservationKeyParam = searchParams.get('reservationKey');

  // 予約枠を表示する日付範囲とその操作関数
  const {
    dateRange,
    handleChangeCurrentDate,
    handlePrevMonth,
    handleNextMonth,
  } = useDateRange(startDateParam, endDateParam);

  // 所要時間
  const [minutesRequired, setMinutesRequired] = useState<number>();

  // オプショナルフィールドの選択状態
  const [selectedOptionalFields, setSelectedOptionalFields] = useState<
    SelectedOptionalField[]
  >([]);

  // リソースの選択状態
  const { resourceResponse, setResourceResponse, handleChangeMember } =
    useResourceResponse(workspaceUid, courseUid);

  // この画面に必要な基本情報
  const {
    workspaceSetting,
    shop,
    course,
    reservationTable,
    formSetting,
    courseSlotSetting,
    groups,
    isLoadingReservationTable,
  } = useReservationTable(
    workspaceUid,
    shopUid || '',
    courseUid || '',
    dateRange,
    reservationKeyParam || undefined,
    minutesRequired,
    resourceResponse,
    selectedOptionalFields
  );

  // カレンダーを強制的に開く
  const { forceOpenCalendar } = useForceOpenCalendar(
    widgetMode,
    normalMode,
    reservationTable
  );

  //カスタムドメインに関する情報
  const { data: shopCustomDomain, isLoading: isLoadingShopCustomDomain } =
    useCustomDomainAttachedShop(workspaceUid, shopUid);

  // 埋め込みタグを挿入
  useUserScript(workspaceSetting, shopSetting);

  const primaryColor = getPrimaryColor(workspaceSetting, shopSetting);

  // 予約時間変更時に呼ばれる予約情報（reservationKeyParamがついている場合）
  const {
    canUpdateReservationDateTimeFromPublic,
    isCanceled,
    originalDateTime,
    originalMiniteRequired,
    isLoadingReservation,
    selectedResources,
  } = useReservation(
    workspaceUid,
    shopUid || '',
    courseUid || '',
    reservationKeyParam
  );

  // フォームの入力状態(useStorageでローカルストレージから復元)
  // 所要時間の算出に必要;
  const [formResponse, setFormResponse] = useStorage(
    getStorageKey(workspaceUid),
    createDefaultFormResponse(),
    'session'
  );
  const [isInitialLoading, setInitialLoading] = useState(true);

  // 所要時間、リソース、オプショナルフィールドの選択を復元
  // 予約時間変更モードの場合
  // - 元の予約の所要時間を minutesRequired に設定
  // - リソースの選択肢の復元
  // - オプショナル選択肢の復元
  useEffect(() => {
    if (!courseSlotSetting) {
      return;
    }
    if (
      originalMiniteRequired !==
      courseSlotSetting?.shopCourseSetting?.minutesRequired
    ) {
      setMinutesRequired(originalMiniteRequired);
    } else {
      setMinutesRequired(undefined);
    }
    if (isInitialLoading) {
      // 対象は選択可能なもののみ
      const newResources =
        selectedResources
          ?.filter((selectedResource) => {
            return courseSlotSetting.resourceConstantSetting?.constants.some(
              (constant) => {
                const target = constant.targets.find(
                  (target) => target.uid === selectedResource.resourceTargetUid
                );
                return target && target.assignmentRule === 'select';
              }
            );
          })
          .map((selectedResource) => {
            return {
              targetUid: selectedResource.resourceTargetUid,
              resourceId: selectedResource.resourceId,
            };
          }) || [];
      setResourceResponse({ resources: newResources });
      const originalSelectedOptionalFields = calcSelectedOptionalFields(
        formResponse,
        courseSlotSetting?.shopCourseSetting
      );
      setSelectedOptionalFields(originalSelectedOptionalFields);
      setInitialLoading(false);
    }
  }, [
    formResponse,
    courseSlotSetting,
    originalMiniteRequired,
    selectedResources,
    reservationKeyParam,
    isInitialLoading,
    setResourceResponse,
    selectedOptionalFields,
  ]);

  // ノーマライズされた結果の日付範囲にURLを書き換える
  // 初回表示時のみ実行
  useNormalizeLocationUrl(searchParams);

  // メインコンテンツ
  const content = (): JSX.Element => {
    if (isLoadingShopCustomDomain || isLoadingReservation || isLoadingCourse) {
      return <Loading primaryColor={primaryColor} />;
    }
    if (!shopSetting && !isLoadingCourse) {
      return <div>店舗設定が読み込めませんでした。</div>;
    }
    if (isCanceled) {
      return <div>この予約はキャンセル済みです。</div>;
    } else if (!canUpdateReservationDateTimeFromPublic) {
      return (
        <div css={styles.expiredReserve}>
          予約の変更期限を過ぎています。お手数ですが店舗に直接ご連絡ください。
        </div>
      );
    }
    return (
      <div>
        <InfoMessage shopSetting={shopSetting} course={course} />
        <MinuteRequiredInfo
          formResponse={formResponse}
          courseSlotSetting={courseSlotSetting}
        />
        <div>
          <Calendar
            workspaceUid={workspaceUid}
            shopUid={shopUid}
            courseUid={courseUid}
            forceOpen={forceOpenCalendar}
            dateRange={dateRange}
            onChangeCurrentDate={handleChangeCurrentDate}
            primaryColor={primaryColor}
            handlePrevMonth={handlePrevMonth}
            handleNextMonth={handleNextMonth}
            widgetMode={widgetMode}
          />
        </div>
        {reservationTable && !getWeeklyReservationsLength(reservationTable) && (
          <EmptyReservationTable
            widgetMode={widgetMode}
            normalMode={normalMode}
          />
        )}
        {isLoadingReservationTable ? (
          <Loading primaryColor={primaryColor} />
        ) : (
          <ReservationTableContent
            courseSlotSetting={courseSlotSetting}
            reservationTable={reservationTable}
            dateRange={dateRange}
            primaryColor={primaryColor}
            widgetMode={widgetMode}
            displayMode={displayMode}
            directContactInfo={{
              allowLateReservation: shopSetting?.allowLateReservation ?? 'none',
              allowReservationWhenFull:
                shopSetting?.allowReservationWhenFull ?? 'none',
              contactLineId: shopSetting?.contactLineId ?? null,
              contactTelNumber: shopSetting?.contactTelNumber ?? null,
            }}
          />
        )}
      </div>
    );
  };

  // ドメインチェック
  if (
    !isLoadingReservationTable &&
    !validDomain(workspaceSetting?.domain) &&
    !isLoadingShopCustomDomain &&
    !validDomain(shopCustomDomain?.fqdn ?? null)
  ) {
    return <DomainError domain={workspaceSetting?.domain} />;
  }

  return (
    <div css={styles.maxWidth}>
      {originalDateTime && (
        <div>
          <HeaderContent
            shop={shop}
            shopSetting={shopSetting}
            workspaceSetting={workspaceSetting}
            primaryColor={primaryColor}
            widgetMode={widgetMode}
          />
          <div css={styles.reservedContainer}>
            <h2
              css={[
                styles.reserveChangeTitle,
                css`
                  color: ${primaryColor};
                `,
              ]}
            >
              予約日時を変更する
            </h2>
            <div>
              <SelectedReserveInfo
                name={course?.name || ''}
                imagePath={course?.imagePath || null}
                dateTime={originalDateTime}
                primaryColor={primaryColor}
              />
            </div>
          </div>
        </div>
      )}
      {courseSlotSetting?.resourceConstantSetting?.constants && (
        <div css={styles.minutesRequiredFields}>
          <ResourceForm
            primaryColor={primaryColor}
            constants={courseSlotSetting?.resourceConstantSetting?.constants}
            groups={groups}
            resourceResponse={resourceResponse}
            onChangeMember={handleChangeMember}
          />
        </div>
      )}
      {content()}
    </div>
  );
}
