/** @jsxImportSource @emotion/react */

import { css } from '@emotion/react';
import { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { Shop } from '../../../@interfaces/shop';
import useShops from '../../../api/use-shops';
import { DomainError, validDomain } from '../../../components/DomainError';
import { PageNotFoundInfo } from '../../../components/PageNotFoundInfo';
import { Loading } from '../../../components/Shared';
import { useTrackLocation } from '../../../hooks/use-track-location';
import useUserScript from '../../../hooks/use-user-script';
import TopArrow from '../../../image/top_arrow.svg';
import { DEFAULT_PRIMARY_COLOR, LOGO_BASE_URL } from '../../../models/theme';
import {
  getInflowSource,
  setInflowSourceParam,
} from '../../../utils/inflow-source';
import { margeParams } from '../../../utils/query-storage';
import Shops from '../components/Shops';

const styles = {
  logoFrame: css`
    background: #ffffff;
  `,
  logoWrapper: css`
    padding: 48px 0;
    width: 55%;
    max-width: 300px;
    margin: 0 auto;
  `,
  logo: css`
    width: 100%;
  `,
  maxWidth: css`
    max-width: 700px;
    margin: 0 auto;
  `,
  scrollTopButton: css`
    position: fixed;
    bottom: 20px;
    right: 20px;
    background: #172b4d;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    opacity: 0;
    transform: translateY(-10px);
    transition: opacity 0.3s, transform 0.3s;
    cursor: pointer;
    &.active {
      opacity: 1;
      transform: translateY(0);
    }
  `,
};

type PageParams = {
  workspaceUid: string;
};

export default function WorkspacePage() {
  useTrackLocation();
  const { workspaceUid } = useParams<PageParams>();
  const [isShow, setIsShow] = useState<boolean>(false);

  const scrollEvent = () => {
    setIsShow(window.scrollY > 300);
  };

  useEffect(() => {
    window.addEventListener('scroll', scrollEvent);
    return () => {
      window.removeEventListener('scroll', scrollEvent);
    };
  }, []);

  const { workspace, workspaceSetting, shops, isLoadingShops } =
    useShops(workspaceUid);

  useUserScript(workspaceSetting, undefined);
  const primaryColor = workspaceSetting?.primaryColor || DEFAULT_PRIMARY_COLOR;

  const handleClickShop = (shop: Shop) => {
    window.location.href = margeParams(`/a/${workspaceUid}/shops/${shop.uid}`);
  };

  const location = useLocation();
  const history = useHistory();
  const inflowSrc = getInflowSource(location.search);
  useEffect(() => {
    if (inflowSrc) {
      history.replace({
        search: setInflowSourceParam(inflowSrc, location.search),
      });
    }
  }, [inflowSrc, history, location.search]);

  if (!isLoadingShops && !validDomain(workspaceSetting?.domain)) {
    return <DomainError domain={workspaceSetting?.domain} />;
  }

  return isLoadingShops ? (
    <div>
      <Loading primaryColor={primaryColor} />
    </div>
  ) : (
    <div css={styles.maxWidth}>
      {shops ? (
        <div>
          <div css={styles.logoFrame}>
            <div css={styles.logoWrapper}>
              <img
                src={`${LOGO_BASE_URL}/${workspaceSetting?.logoPath}`}
                alt=""
                css={styles.logo}
              />
            </div>
          </div>
          <Shops
            shops={shops}
            primaryColor={primaryColor}
            handleClickShop={handleClickShop}
            workspaceSetting={workspaceSetting}
          />
          <button
            css={styles.scrollTopButton}
            className={isShow ? 'active' : ''}
            onClick={() => {
              window.scrollTo(0, 0);
            }}
          >
            <img src={TopArrow} alt="" />
          </button>
        </div>
      ) : (
        <PageNotFoundInfo target={shops}>店舗は存在しません。</PageNotFoundInfo>
      )}
    </div>
  );
}
